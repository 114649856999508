/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl, Alert, AlertIcon,
  FormLabel,
  Heading,
  SimpleGrid,
  Icon, useToast,
  Input,
  InputGroup,
  Spinner, Select, DropdownToggle, DropdownMenu, DropdownItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import Haikunator from "haikunator";
import GitHubLogin from "../githubLogin";
var haikunator = new Haikunator()
// import TerminalComponent from "../terminal";

function Home() {
  const apiUrl = "http://localhost:8080";// "https://api-api-1-c9caeb82.znffz4.alpha.on-acorn.io";//
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        {/* <TotalSpent />
        <WeeklyRevenue /> */}<GitHubLogin />
      </SimpleGrid>
    </Box>

  );
}

export default Home;

/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Notifications from "views/admin/profile/components/Notifications";
import Projects from "views/admin/profile/components/Projects";
import Storage from "views/admin/profile/components/Storage";
import Upload from "views/admin/profile/components/Upload";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import React from "react";

export default function Overview() {
  // center the Box below
  return (
    <Box
      pt={{ base: "130px", md: "480px", xl: "80px" }}
      // display="flex"
      // alignItems="center"
      // justifyContent="center"
      // height="100vh" // Make sure the Box takes the full height of the viewport
      maxWidth="600px" // Make sure the Box takes the full width of the viewport
      margin={"auto"}
    >
          <Projects
            gridArea="1 / 2 / 2 / 2"
            banner={banner}
            avatar={avatar}
            name="Adela Parkson"
            job="Product Designer"
            posts="17"
            followers="9.7k"
            following="274"
          />
    </Box>


    // <Box pt={{ base: "130px", md: "180px", xl: "80px" }}
    //   display='flex'
    //   justifyContent='center'
    //   alignItems='center'
    //   w='100%'
    //   h='100%'
    // >
    //   <Grid
    //     mb='20px'
    //     templateColumns={{
    //       base: "1fr",
    //       lg: "repeat(2, 1fr)",
    //       "2xl": "1.34fr 1.62fr 1fr",
    //     }}
    //     templateRows={{
    //       base: "1fr",
    //       lg: "repeat(2, 1fr)",
    //       "2xl": "1fr",
    //     }}
    //     gap={{ base: "20px", xl: "20px" }}>
    //     <Projects
    //       gridArea='1 / 2 / 2 / 2'
    //       banner={banner}
    //       avatar={avatar}
    //       name='Adela Parkson'
    //       job='Product Designer'
    //       posts='17'
    //       followers='9.7k'
    //       following='274'
    //     />
    //   </Grid>
    // </Box>
    );
}

import { Text, useColorModeValue } from "@chakra-ui/react";
import Project1 from "assets/img/profile/Project1.png";
import Project3 from "assets/img/profile/Project3.png";
import Card from "components/card/Card.js";
import React from "react";
import Project from "views/admin/profile/components/Project";

export default function Projects(props) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const accessToken = localStorage.getItem('githubToken');
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Let's deploy a rollup
        <br/>
      </Text>
      <Project
        boxShadow={cardShadow}
        mb='20px'
        image={Project1}
        ranking='1'
        marginTop='40px'
        link='create'
        title='Deploy a sample rollup'
        content='Deploy a sample rollup to experience the one click deployment process'
      />
      <Project
        boxShadow={cardShadow}
        mb='20px'
        image={Project3}
        ranking='2'
        link='create'
        title="I've got a smart contract"
        content="I know what I'm doing. Let me deploy a smart contract to a new rollup"
      />
      {/* {!accessToken ? <GitHubLogin /> : <Repo />} /admin/repo' */}
    </Card>
  );
}
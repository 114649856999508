import React from 'react';
import axios from 'axios';
import GitHubCallback from './callback';
import { Button } from '@chakra-ui/react';

const clientId = 'e32d506900a28c69107b';
const redirectUri = 'http://localhost:3000/callback';
const GITHUB_URL = `https://github.com/login/oauth/authorize`;
const GitHubLogin = () => {
  const handleGithubConnect = () => {
    window.location.href = `${GITHUB_URL}?client_id=${clientId}&scope=public_repo&redirect_uri=${redirectUri}`;
  };
  const url = window.location.href;
  const hasCode = url.includes("?code=");

  return ( !hasCode ? (
    <div>
      <Button
        fontSize='sm'
        variant='brand'
        fontWeight='500'
        w='100%'
        h='50'
        onClick={handleGithubConnect}
        mb='24px'>
        Connect GitHub
      </Button>

    </div>) : <GitHubCallback />
  );
};

export default GitHubLogin;

/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl, Alert, AlertIcon,
  FormLabel,
  Heading,
  Icon, useToast,
  Input,
  InputGroup,
  Spinner, Select, DropdownToggle, DropdownMenu, DropdownItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import Haikunator from "haikunator";
var haikunator = new Haikunator()
// import TerminalComponent from "../terminal";

function CreateRollup() {
  // const apiUrl = "http://localhost:8080";
  // const apiUrl = "https://api-api-1-c9caeb82.mlfnml.oss-acorn.io";
  const apiUrl = "https://api-api-1.gnd8qd.oss-acorn.io";

  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [loading, setLoading] = useState(false);
  const [executionLayer, setExecutionLayer] = useState("cairo");
  const [sequencer, setSequencer] = useState("rollkit");
  const [daLayer, setDaLayer] = useState("celestia");
  const [interoperabilityLayer, setInteroperabilityLayer] = useState("hyperlane");
  const [rollupName, setRollupName] = useState(haikunator.haikunate({ tokenHex: true }));
  const [resp, setResp] = useState("");
  const [statusResp, setStatusResp] = useState("");
  const [existingRollups, setExistingRollups] = useState([]);
  const toast = useToast()
  const [showToast, setShowToast] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = async () => {

    const data = {
      namespace: rollupName,
      address: "starkmint:26658",
      image: ".",
      execution: executionLayer,
      sequencer: sequencer,
      da: daLayer,
      interoperability: interoperabilityLayer,
    };

  if (rollupName === "" || executionLayer === "" || sequencer === "" || daLayer === "") {
      setErrorMsg("Please fill all the fields!");
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post(`${apiUrl}/run`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setResp(response?.data?.msg);
      setShowToast(true);
      setStatusResp("");
      // console.log(response);
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to create rollup!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DefaultAuth >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
        {sequencer !== "madara" ? (
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Customize your rollup
          </Heading>
        ) : (
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Let's create a {sequencer} rollup
          </Heading>
        )}
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>


          {/* add rainbow wallet auth here */}
          <Flex align='center' mb='25px'>
          </Flex>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Rollup Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder={rollupName}
              onChange={(e) => setRollupName(e.target.value)}
              mb='24px'
              fontWeight='500'
              size='lg'
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Execution<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select placeholder='Select option'
                          isRequired={true}
                          variant='filled'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          type='text'
                          mb='24px'
                          fontWeight='500'
                          size='lg' defaultValue={"cairo"}
                          onChange={(e) => setExecutionLayer(e.target.value)}
              >
              <option value="cairo">CairoVM</option>
              <option value="fuel">FuelVM</option>
              <option value="evm">EVM Rollapp</option>
              <option value="cosmwasm">CosmWasm</option>
              <option value="zkvm">zkVM</option>
              <option value="move" disabled>moveVM</option>
              <option value="zkmlvm" disabled>zkmlVM</option>
              <option value="taiko" disabled>Taiko zkEVM</option>
              <option value="scroll" disabled>Scroll</option>
              <option value="zksync" disabled>zksync</option>
              <option value="polygon" disabled>Polygon zkEVM</option>
            </Select>

            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Sequencer<Text color={brandStars}>*</Text>
            </FormLabel>
            <Select placeholder='Select option'
                          isRequired={true}
                          variant='filled'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          type='text'
                          mb='24px'
                          fontWeight='500'
                          onChange={(e) => setSequencer(e.target.value)}
                          size='lg' defaultValue={"rollkit"}
            
            >
              <option value="rollkit">Rollkit</option>
              <option value="astria" disabled>Astria</option>
              <option value="espresso" disabled>Espresso</option>
              <option value="madara">Madara</option>
              <option value="dymension">Dymension</option>
            </Select>

            {sequencer !== "madara" ? (
                <>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                onChange={(e) => setDaLayer(e.target.value)}
                display='flex'>
                Data Availability<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select placeholder='Select option'
                            isRequired={true}
                            variant='filled'
                            fontSize='sm'
                            ms={{ base: "0px", md: "0px" }}
                            type='text'
                            mb='24px'
                            fontWeight='500'
                            size='lg' defaultValue={"celestia"}
              
              >
                <option value="celestia" defaultValue={"celestia"} >Celestia</option>
                <option value="solana" disabled>Solana</option>
                <option value="eigenda" disabled>Eigen</option>
                <option value="avail" disabled>Avail</option>
                <option value="bitcoin" disabled>Bitcoin DA</option>

              </Select>

              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                onChange={(e) => setInteroperabilityLayer(e.target.value)}
                display='flex'>
                Interoperability<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select placeholder='Select option'
                            isRequired={true}
                            variant='filled'
                            fontSize='sm'
                            ms={{ base: "0px", md: "0px" }}
                            type='text'
                            mb='24px'
                            fontWeight='500'
                            size='lg' defaultValue={"hyperlane"}
              
              >
                <option value="hyperlane" defaultValue={"hyperlane"} >Hyperlane</option>
                <option value="ibc">IBC</option>
                <option value="connext" disabled>Connext</option>
              </Select>
              </>
            ) : null}

          {loading
             ? (
              <Spinner
                thickness='4px'
                // speed='0.65s'
                // emptyColor='gray.200'
                // color='brandStars'
                size='xl'
              />
            ) : (
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              onClick={handleSubmit}
              mb='24px'>
              Create Rollup
            </Button>
            )}
            
          </FormControl>

          {showToast ? (
            <div>
            <Alert status='success' style={{ marginBottom: '20px' }}>
              <AlertIcon />
                {resp}
            </Alert>
            <NavLink to={'/admin/deployments'}>
                <Button
                  fontSize='sm'
                  variant='brand'
                  fontWeight='500'
                  w='100%'
                  h='50'
                  mb='24px'>
                  Go to Deployments
                </Button>
            </NavLink>
            </div>
          ) : null}
          {errorMsg.length > 0 ? (
            <Alert status='error'>
              <AlertIcon />
                {errorMsg}
            </Alert>

          ) : null}

        </Flex>

      </Flex>
      {/* <div style={{ width: '800px', height: '400px' }}>
        <TerminalComponent />
      </div> */}

    </DefaultAuth>
  );
}

export default CreateRollup;

import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th, Link, Button,
  Thead, Spinner,
  Tr, Image,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import cairo from "assets/img/cairo.png";

import axios from "axios";

// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
export default function ColumnsTable(props) {
  const { columnsData, tableData, getRollups } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 20;
  // const apiUrl = "http://localhost:8080";// "https://api-api-1-c9caeb82.znffz4.alpha.on-acorn.io";//
  // const apiUrl = "https://api-api-1-c9caeb82.mlfnml.oss-acorn.io";//
  const apiUrl = "https://api-api-1.gnd8qd.oss-acorn.io";
  // const apiUrl = "https://api-api-1.a.exe.vistara.network";

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [loading, setLoading] = React.useState();
  const [interatedRollup, setInteratedRollup] = React.useState('');

  const handleRollupAction = async (rollupName, action) => {
    setInteratedRollup(rollupName);
    setLoading(true);
    console.log(`Stopping ${rollupName}`);
    const data = {
      name: rollupName,
      action: action.toLowerCase(),
    };
    try {
      await axios.post(`${apiUrl}/app`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setTimeout(() => {
        console.log("Getting rollups again...")
        getRollups();
        setLoading(false);
      }
      , 2000);

      // setInteratedRollup('');
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      // setInteratedRollup('');
    }
  };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Deployed rollups
        </Text>
        <Link
              href={"/admin/create"}
              mt={{
                base: "0px",
                md: "10px",
                lg: "0px",
                xl: "10px",
                "2xl": "0px",
              }}>
              <Button
                variant='darkBrand'
                color='white'
                fontSize='sm'
                fontWeight='500'
                borderRadius='70px'
                px='24px'
                py='5px'>
                Create Rollup
              </Button>
            </Link>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            const rollupName = row.values.name;
            const rollupStatus = row.values.status;
            // find a better way
            // const imgSrc = row.values?.endpoints[0].indexOf("st-") > 0
            //   ? `https://pbs.twimg.com/profile_images/1613197238670336002/ozRVkILw_400x400.jpg`
            //   : `https://www.cairo-lang.org/docs/_static/logo.png`;
            let imgSrc = cairo;//`https://www.cairo-lang.org/docs/_static/logo.png`
            if (row.values?.endpoints[0].indexOf("ttps://st-") > 0) {
              imgSrc = `https://pbs.twimg.com/profile_images/1613197238670336002/ozRVkILw_400x400.jpg`
            } else if (row.values?.endpoints[0].indexOf("rollappevm-") > 0) {
              imgSrc = `https://pbs.twimg.com/profile_images/1663159896701820928/by0GLREA_400x400.jpg`

            }
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                    // console.log(`cell.cells is ${cell.values}`)
                    let data = "";
                  if (cell.column.Header === "NAME") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "VM") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                      <Image
                        src={imgSrc}
                        w={{ base: "100%", "3xl": "100%" }}
                        h={{ base: "100%", "3xl": "100%" }}
                        borderRadius='10px'
                        maxWidth={{ base: "40px", "3xl": "40px" }}
                      />
                      </Text>
                    );
                  } else if (cell.column.Header === "STATUS") {
                    data = (
                      <Flex align='center'>
                        <Icon
                          w='24px'
                          h='24px'
                          me='5px'
                          color={
                            cell.value == "1" || cell.value == "2" || parseInt(cell.value) > 1
                              ? "green.500"
                              : cell.value === "stopped"
                              ? "red.500"
                              : cell.value === "Error"
                              ? "orange.500"
                              : null
                          }
                          as={
                            cell.value == "1" || cell.value == "2" || parseInt(cell.value) > 1
                              ? MdCheckCircle
                              : cell.value === "stopped"
                              ? MdCancel
                              : cell.value === "Error"
                              ? MdOutlineError
                              : null
                          }
                        />
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value == "1" || cell.value == "2" || parseInt(cell.value) > 1 ? "OK" : cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "CREATED") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value} ago
                      </Text>
                    );
                  } else if (cell.column.Header === "SEQUENCER") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "EXPLORER") {
                    console.log(`in explorer ${cell.value}`)
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value ? (
                          <>
                          <Link as={Link} href={cell.value} isExternal>
                          <Button
                            fontSize='sm'
                            variant='brand'
                            fontWeight='500'
                            w='100%'
                            h='50'
                            href={cell.value}
                            mb='24px'>
                            Open
                          </Button>
                          </Link>
                        </>
                        ) : ""}
                        
                      </Text>
                    );
                  } else if (cell.column.Header === "PROGRESS") {
                    data = (
                      <Flex align='center'>
                        <Progress
                          variant='table'
                          colorScheme='brandScheme'
                          h='8px'
                          w='108px'
                          value={cell.value}
                        />
                      </Flex>
                    );
                  } else {
                  // show loading spinner if loading
                  if (loading && rollupName === interatedRollup) {
                    data = (
                      <Button
                        fontSize='sm'
                        variant='brand'
                        fontWeight='500'
                        w='100%'
                        h='50'
                        mb='24px'>
                        {rollupStatus === "stopped" ? "Starting..." : "Stopping..."}
                        <Spinner

                          thickness='4px'
                          speed='0.65s'
                          emptyColor='gray.200'
                          color='brandScheme.500'
                          size='sm'
                        />
                      </Button>
                    );

                  } else {
                    let action = rollupStatus === "stopped" ? "Start" : "Stop"

                      data = (
                        <Button
                          fontSize='sm'
                          variant='brand'
                          fontWeight='500'
                          w='100%'
                          h='50'
                          onClick={() => handleRollupAction(rollupName, action)}
                            mb='24px'>
                          {action}
                        </Button>
          
                      );
                    }

                  }

                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {/* <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div> */}
    </Card>
  );
}

import React, { useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const clientId = 'e32d506900a28c69107b';
const clientSecret = 'fa2fe6ae2788c789dc6ee9d84546659b9c469b3c';

const GitHubCallback = () => {
  const history = useHistory();

  useEffect(() => {
    // const url = new URL(window.location.href);
    // const code = url.searchParams.get('code');
    const url = window.location.href;
    const hasCode = url.includes("?code=");

    console.log(hasCode);console.log(window.location)
    if (hasCode) {
      const code = url.split("?code=")[1].replace("#/", "");
      axios
        .post('http://localhost:8080/github_oauth', {
          code: code,
        }, {
          headers: {
            'Accept': 'application/json',
          },
        })
        .then((response) => {
            console.log(response)
          const accessToken = response.data.access_token;
          localStorage.setItem('githubToken', accessToken);
          history.push('/repos');
        })
        .catch((error) => {
          console.log(error);
        //   history.push('/');
        console.log("hereee")
        });
    }
  }, [history]);

  return (
    <div>
      <h2>Loadings...</h2>
    </div>
  );
};

export default GitHubCallback;

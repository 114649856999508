import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock, MdCreateNewFolder,
  MdOutlineShoppingCart,
} from "react-icons/md";

// Admin Imports
// import MainDashboard from "views/admin/default";
import Home from "views/admin/home";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import CreateRollup from "views/admin/createRollup";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import Repo from "views/admin/repo";
// import Repo from "views/admin/githubLogin/repo";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    path: "/home",
    component: Profile,
  },
  {
    name: "Repositories",
    layout: "/admin",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    path: "/repo",
    component: Repo,
  },
  {
    name: "Create Rollup",
    layout: "/admin",
    icon: <Icon as={MdCreateNewFolder} width='20px' height='20px' color='inherit' />,
    path: "/create",
    component: CreateRollup,
  },
  {
    name: "Deployments",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/deployments",
    component: DataTables,
  },
  {
    name: "Blueprints",
    layout: "/admin",
    path: "/blueprints",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: NFTMarketplace,
    secondary: true,
  },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "/sign-in",
  //   icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
  //   component: SignInCentered,
  // },
];

export default routes;

/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import DeploymentTable from "views/admin/dataTables/components/DeploymentTable";

import {
  columnsDataComplex,
} from "views/admin/dataTables/variables/columnsData";
import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Settings() {
  // const apiUrl = "https://api-api-1-c9caeb82.mlfnml.oss-acorn.io";//process.env.apiUrl || "http://localhost:8080";
  // const apiUrl = "http://localhost:8080";//process.env.apiUrl || "http://localhost:8080";
  const apiUrl = "https://api-api-1.gnd8qd.oss-acorn.io";
  // const apiUrl = "https://api-api-1.a.exe.vistara.network";
  const [loading, setLoading] = useState(false);
  const [statusResp, setStatusResp] = useState("");
  const [existingRollups, setExistingRollups] = useState([]);

  const getRollups = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/apps`);
      
      setExistingRollups(response?.data?.filter((item) => item?.name !== "api-1"));
      setStatusResp("");
      console.log(response?.data);
    } catch (error) {
      console.error("Error:", error);
      setStatusResp("Failed to get existing rollups!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function main() {
      const data = await getRollups();
    }
    main();
  }, []);


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <DeploymentTable
          columnsData={columnsDataComplex}
          tableData={existingRollups}
          getRollups={getRollups}
          loading={loading}
        />
      </SimpleGrid>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import { Table, Tbody, Tr, Td, Box, Button, 
  AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
  Flex
} from '@chakra-ui/react';
import GitHubLogin from '../githubLogin';

const Repo = () => {
  const [repos, setRepos] = useState([]);
  const accessToken = localStorage.getItem('githubToken');
  console.log(accessToken)
  const [selectedRepository, setSelectedRepository] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleImportClick = (repository) => {
    setSelectedRepository(repository);
    setIsOpen(true);
    console.log(selectedRepository)
  };

  const handleClose = () => {
    setSelectedRepository(null);
    setIsOpen(false);
  };

  useEffect(() => {
    // Fetch repositories from API or replace with your own data source
    fetch('https://api.github.com/user/repos', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setRepos(data))
      .catch((error) => console.error('Error fetching repositories:', error));
  }, []);

  const handleRepoClick = (repo) => {
    setSelectedRepository(repo);
  };

  return (
    <Flex
    maxW={{ base: "100%", md: "max-content" }}
    w='100%'
    mx={{ base: "auto", lg: "0px" }}
    me='auto'
    h='100%'
    alignItems='start'
    justifyContent='center'
    mb={{ base: "30px", md: "60px" }}
    px={{ base: "25px", md: "0px" }}
    mt={{ base: "40px", md: "14vh" }}
    flexDirection='column'>

    {!accessToken ? <GitHubLogin/> : 
    (
      <Box overflowX="scroll" maxHeight={'500px'}>
        <Table variant="simple" maxHeight={`100px`} overflowX={'auto'}>
        <Tbody>
          {repos.map((repository, index) => (
            <Tr key={index}>
              <Td>{repository.name}</Td>
              <Td>
                <Button colorScheme="teal" size="sm" onClick={() => handleImportClick(repository)}>
                  Deploy
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
        </Table>

        <AlertDialog isOpen={isOpen} onClose={handleClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Repository Details
              </AlertDialogHeader>

              <AlertDialogBody>
              <AlertDialogBody>
                <strong>Repository Name:</strong> {selectedRepository?.name}<br />
                <strong>Language:</strong> {selectedRepository?.settings?.language}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button colorScheme="red" onClick={handleClose} ml={3}>
                  Close
                </Button>
              </AlertDialogFooter>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
        </AlertDialog>
      </Box>
      )}
    </Flex>
)}

export default Repo;
